import './stylesvg.css';
export const dish = () => {
    return (
<svg className="dish-svg"  viewBox="0 0 185 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M104.798 34.0545C104.798 31.9451 107.762 30.4141 112.306 30.4141C117.489 30.4141 122.358 32.2529 125.388 34.8378C125.952 35.3173 126.812 35.1669 127.18 34.5239L130.879 27.1687C131.016 26.929 131.061 26.6473 131.006 26.3767C130.95 26.1061 130.797 25.8653 130.576 25.6996C126.005 22.2655 119.855 19.7983 112.207 19.7983C99.6626 19.7983 92.6515 26.8115 92.6515 34.8122C92.6515 53.4921 120.999 47.0273 120.999 55.2408C120.999 57.4882 118.431 59.3914 112.999 59.3914C107.601 59.3914 101.222 57.2623 97.5139 54.3182C96.9615 53.8795 96.1465 54.0279 95.7742 54.6301L91.5738 61.6558C91.4309 61.8862 91.3757 62.1603 91.4184 62.4281C91.4611 62.6958 91.5988 62.9392 91.8063 63.1138C96.8669 67.3659 104.501 69.8754 112.407 69.8754C125.642 69.8754 133.051 63.3573 133.051 54.5677C133.045 35.7434 104.798 41.5582 104.798 34.0545Z" fill="#EC1944"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M47.645 2.2085H36.6105C36.3973 2.20876 36.1929 2.29362 36.0421 2.44444C35.8914 2.59526 35.8067 2.79972 35.8065 3.01294V26.3042C32.1093 22.2654 27.1502 19.8047 21.5689 19.8047C9.65698 19.8047 0 31.0137 0 44.8407C0 58.6668 9.65648 69.8757 21.5663 69.8757C27.1472 69.8757 32.1088 67.4156 35.804 63.3767V67.8865C35.8042 68.0997 35.8889 68.3041 36.0396 68.455C36.1903 68.6058 36.3948 68.6906 36.608 68.6909H47.643C47.8563 68.6908 48.0608 68.606 48.2116 68.4551C48.3625 68.3043 48.4473 68.0998 48.4474 67.8865V3.01093C48.4474 2.56821 48.0882 2.2085 47.645 2.2085ZM24.2157 59.361C17.8137 59.361 12.6263 52.8575 12.6263 44.8337C12.6263 36.8093 17.8157 30.3063 24.2162 30.3063C30.5884 30.3063 35.7583 36.754 35.8035 44.726V44.9388C35.7608 52.9159 30.5899 59.361 24.2157 59.361Z" fill="#EC1944"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M165.626 19.8008C159.795 19.8008 155.312 22.2503 152.367 26.2479V3.01101C152.367 2.79769 152.282 2.5931 152.132 2.44217C151.981 2.29125 151.776 2.20632 151.563 2.20605H140.528C140.315 2.20619 140.11 2.29105 139.959 2.442C139.808 2.59294 139.724 2.79761 139.724 3.01101V67.886C139.724 68.0993 139.809 68.3039 139.959 68.4547C140.11 68.6055 140.315 68.6903 140.528 68.6905H151.563C151.776 68.6902 151.981 68.6054 152.131 68.4545C152.282 68.3037 152.367 68.0993 152.367 67.886V40.1434C152.376 33.8633 156.862 30.3622 162.28 30.3622C167.703 30.3622 171.962 33.6957 171.962 41.8811L171.972 67.886C171.972 68.0993 172.057 68.3039 172.208 68.4547C172.359 68.6055 172.563 68.6903 172.777 68.6905H183.812C184.025 68.6902 184.229 68.6054 184.38 68.4545C184.531 68.3037 184.616 68.0993 184.616 67.886V41.1113C184.618 26.3903 176.169 19.8008 165.626 19.8008Z" fill="#EC1944"/>
<path d="M62.785 7.47095C62.785 3.34206 66.2262 0 70.256 0C74.3855 0 77.7276 3.34206 77.7276 7.47095C77.7276 11.6003 74.3855 14.9424 70.2565 14.9424C66.2262 14.9424 62.7855 11.6003 62.7855 7.47145L62.785 7.47095ZM84.5581 56.5503C79.5085 59.4834 74.0937 60.2581 70.3642 60.2642C66.6347 60.2581 61.2179 59.4859 56.1698 56.5503C55.3417 56.0875 54.901 56.8964 55.073 57.6018C56.7871 64.6451 62.9571 69.8687 70.3189 69.912H70.4095C77.7708 69.8687 83.9408 64.6471 85.6549 57.6018C85.8269 56.8944 85.3862 56.086 84.5581 56.5503ZM81.5707 38.7624C77.5837 41.0787 73.3083 41.6894 70.3642 41.6939C67.4201 41.6894 63.1422 41.0787 59.1572 38.7624C58.5031 38.3972 58.155 39.0356 58.2903 39.5925C59.6432 45.1542 64.5142 49.2791 70.3275 49.3113H70.3984C76.2117 49.2771 81.0827 45.1542 82.4355 39.5925C82.5734 39.0356 82.2247 38.3972 81.5707 38.7624ZM78.3901 23.6032C75.5361 25.2609 72.4717 25.6996 70.3642 25.7021C68.2562 25.6976 65.1918 25.2609 62.3378 23.6032C61.8689 23.3406 61.6219 23.7989 61.719 24.1968C62.6884 28.1793 66.1764 31.132 70.338 31.1556H70.3899C74.5535 31.132 78.042 28.1793 79.0094 24.1963C79.1065 23.7989 78.857 23.3426 78.3906 23.6027L78.3901 23.6032Z" fill="#EC1944"/>
</svg>
    )
}